import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    title: {
      color: theme.palette.primary.dark,
    },
    passwordSubtitle: {
      textAlign: 'left',
      color: '#666',
    },
    subtitle2: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      color: '#666',
    },
    inputWrapper: {
      marginBottom: theme.spacing(2),
    },
    phoneWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginBottom: theme.spacing(2),
    },
    phonePrefix: {
      marginRight: '1rem !important',
      width: '5rem !important',
    },
    phoneNumber: {
      flex: '1',
    },
    subtitlePassword: {
      marginTop: 5,
      color: theme.palette.secondary.dark,
    },
    error: {
      color: theme.palette.primary.dark,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonStyle: {
      background: 'linear-gradient(45deg, #321758 10%, #6c4e97 90%)',
      marginTop: '10px',
      marginBottom: '10px',
      fontSize: 16,
      borderRadius: 50,
      border: 0,
      color: '#FFF',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 2px 3px 1px #CCC',
    },
    buttonText: {
      textTransform: 'none',
    },
  })
);
