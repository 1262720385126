import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { globalConfig } from './config/global.config';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import themeProvider from './config/theme-provider';
import './axios';
import './index.css';
import Home from './pages/home/home';
import Validation from './pages/validation/validation';
import Success from './pages/success/success';

globalConfig();

const theme = themeProvider();
const history = createBrowserHistory();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router history={history}>
      <Switch>
        <Route exact={true} path='/invite/:referralCode' component={Home} />
        <Route path='/validation' component={Validation} />
        <Route path='/success' component={Success} />
      </Switch>
    </Router>
  </ThemeProvider>,

  document.getElementById('root')
);
