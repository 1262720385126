import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },
    card: {
      borderRadius: 0,
      margin: theme.spacing(0, 2),
      width: '100%',
      maxWidth: '29rem',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(6),
    },
    subtitle: {
      marginTop: 5,
      textAlign: 'center',
      color: '#666',
    },
    subtitle2: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      color: '#666',
    },
  })
);
