import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Data from '../../model/data';
import { getEndpoints } from '../../helpers/configs';

interface UseGetUser {
  (): {
    user?: Data;
    message: string;
    referralCode: string;
    onErrorDialogWithMessageClose: () => void;
  };
}

export const useGetUsers: UseGetUser = () => {
  const [user, setUser] = useState<Data | undefined>();
  const [message, setMessage] = useState('');

  const onErrorDialogWithMessageClose = useCallback(() => {
    setMessage('');
  }, []);

  const { referralCode } = useParams<{ referralCode: string }>();

  const endpoints = getEndpoints();

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(`${endpoints.api}v1/mobile/coupon/${referralCode}`);

        setUser(response.data);
      } catch {
        setMessage('Não foi possível obter os dados, tente novamente.');
      }
    }
    getUser();
  }, [endpoints.api, referralCode, setMessage]);

  return { user, referralCode, message, onErrorDialogWithMessageClose };
};
