import { Form, Formik } from 'formik';
import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './register-form.style';
import GenericTextField from '../generic-text-field/generic-text-field';
import { FieldFormatType } from '../../model/field-format-type';
import { RegisterUserFormSchema } from '../../model/register-user-form-schema';
import { object, string } from 'yup';
import { getDDDRegex, getPhoneNumberRegex } from '../../helpers/format-phone-number';

interface Props {
  onSubmit: (values: RegisterUserFormSchema) => void;
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  ddd: '',
  telephone: '',
  password: '',
};

const registerValidation = object().shape({
  firstName: string().required('O campo Nome é obrigatório.').min(2).max(100),
  lastName: string().required('O campo Sobrenome é obrigatório.').min(2).max(100),
  email: string()
    .required('O campo E-mail é obrigatório.')
    .email('O campo E-mail deve ser um e-mail válido.'),
  ddd: string()
    .required('O campo DDD é obrigatório.')
    .matches(getDDDRegex(), 'O campo DDD precisa conter dois dígitos.'),
  telephone: string()
    .required('O campo Celular é obrigatório.')
    .matches(getPhoneNumberRegex(), 'O campo Celular precisa conter nove dígitos.'),
  password: string().required('O campo Senha é obrigatório.').min(2).max(20),
});

export default function RegisterForm(props: Props) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerValidation}
      onSubmit={props.onSubmit}
    >
      <Form data-testid='form'>
        <Box className={classes.inputWrapper}>
          <GenericTextField id='firstName' label='Nome' name='firstName' fieldType='text' />
        </Box>
        <Box className={classes.inputWrapper}>
          <GenericTextField id='lastName' label='Sobrenome' name='lastName' fieldType='text' />
        </Box>
        <Box className={classes.inputWrapper}>
          <GenericTextField id='email' label='E-mail' name='email' fieldType='email' />
        </Box>
        <Box className={classes.phoneWrapper}>
          <GenericTextField
            id='ddd'
            label='DDD'
            name='ddd'
            fieldType='text'
            fieldFormatType={FieldFormatType.TELEPHONE}
            className={classes.phonePrefix}
          />
          <GenericTextField
            id='telephone'
            label='Celular'
            name='telephone'
            fieldType='tel'
            fieldFormatType={FieldFormatType.TELEPHONE}
            className={classes.phoneNumber}
          />
        </Box>
        <Box className={classes.inputWrapper}>
          <GenericTextField
            id='password'
            label='Senha'
            name='password'
            fieldType='password'
            className={classes.phoneNumber}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button className={classes.buttonStyle} size='large' type='submit'>
            <Typography className={classes.buttonText}>Cadastrar</Typography>
          </Button>
        </Box>
      </Form>
    </Formik>
  );
}
