import React, { ReactElement } from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import ToolbarWrapper from '../../components/toolbar/toolbar';

import { useStyles } from './success.styles';

export default function Success(): ReactElement {
  const classes = useStyles();

  return (
    <Box className={classes.root} data-testid='success-container'>
      <Card className={classes.card}>
        <ToolbarWrapper />
        <CardContent className={classes.cardContent}>
          <Typography variant='h6' color='primary' align='center'>
            Parabéns você foi cadastrado com sucesso. Agora é só aproveitar o beneficio de comer com
            o Pede Pronto!
          </Typography>
          <Box className={classes.buttonGroup}>
            <a
              href='https://play.google.com/store/apps/details?id=com.onyo&hl=pt_BR'
              className={classes.storeAncor}
            >
              <img src='/btn-android.png' alt='Android Store' className={classes.storeImage} />
            </a>
            <a
              href='https://itunes.apple.com/br/app/onyo/id1132629081'
              className={classes.storeAncor}
            >
              <img src='/btn-apple.png' alt='Apple Store' className={classes.storeImage} />
            </a>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
