import config from 'react-global-configuration';
import { DEVELOPMENT, PRODUCTION, Endpoints } from '../model/endpoints';

import { stagingEndpoints } from '../config/application/staging.endpoint.config';
import { developmentEndpoints } from '../config/application/development.endpoint.config';
import { onyoEndpoints } from '../config/application/onyo.endpoint.config';

export function globalConfig() {
  const endpoints = getEndpoints();
  config.set({ endpoints });
}

function getEndpoints(): Endpoints {
  const env = process.env.REACT_APP_ENV;
  if (env === DEVELOPMENT) {
    return developmentEndpoints;
  } else if (env === PRODUCTION) {
    return onyoEndpoints;
  }

  return stagingEndpoints;
}
