import { useState, useCallback } from 'react';
import axios from 'axios';
import { OnSubmitUpdateRequest } from '../../model/update-request';
import { getEndpoints } from '../../helpers/configs';

interface UseUpdateRegister {
  (): {
    loadingUpdateRegister: boolean;
    onSubmitUpdateUser: OnSubmitUpdateRequest;
    message: string;
    onErrorDialogWithMessageClose: () => void;
    token: string;
  };
}

export const useUpdateRegister: UseUpdateRegister = () => {
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [loadingUpdateRegister, setLoadingUpdateRegister] = useState(false);

  const onErrorDialogWithMessageClose = useCallback(() => {
    setMessage('');
  }, []);

  const endpoints = getEndpoints();

  const onSubmitUpdateUser: OnSubmitUpdateRequest = useCallback(
    async (data) => {
      setLoadingUpdateRegister(true);

      try {
        const { coupon, email, firstName, lastName, password, ddd, telephone, token } = data;

        const fullTelephone = `${ddd}${telephone}`;

        const response = await axios.post<{ token: string }>(
          `${endpoints.api}v1/mobile/temporary_user`,
          {
            coupon,
            email,
            firstName,
            lastName,
            password,
            telephone: fullTelephone,
            token,
          }
        );

        setLoadingUpdateRegister(false);
        setToken(response.data.token);
      } catch (error) {
        setLoadingUpdateRegister(false);
        setMessage('Não foi possível atualizar os dados do usuário.');
      }
    },
    [endpoints.api, setToken]
  );

  return {
    loadingUpdateRegister,
    onSubmitUpdateUser,
    message,
    onErrorDialogWithMessageClose,
    token,
  };
};
