import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      borderRadius: 0,
      margin: theme.spacing(0, 2),
      width: '100%',
      maxWidth: '29rem',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    subtitle: {
      marginTop: 5,
      textAlign: 'center',
      color: '#666',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonStyle: {
      background: 'linear-gradient(45deg, #f06292 10%, #e91e63 90%)',
      marginTop: '10px',
      marginBottom: '10px',
      fontSize: 16,
      borderRadius: 50,
      border: 0,
      color: '#FFF',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 2px 3px 1px #CCC',
    },
    buttonText: {
      textTransform: 'none',
    },
  })
);
