import React, { ReactElement, useCallback } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { useRegister } from '../../services/use-register/use-register';
import { RegisterUserFormSchema } from '../../model/register-user-form-schema';
import { useGetUsers } from '../../services/use-get-users/use-get-users';
import { useAlreadyRegisteredUser } from '../../services/use-already-registered/use-already-registered';

import Header from '../../components/header/header';
import Progress from '../../components/progress/progress';
import RegisterForm from '../../components/register-form/register-form';
import StandardDialog from '../../components/standard-dialog/standard-dialog';

import { useStyles } from './home.styles';

export default function Home(): ReactElement {
  const classes = useStyles();

  const {
    user,
    referralCode,
    message: getUsersMessage,
    onErrorDialogWithMessageClose: onCloseGetUsersMessage,
  } = useGetUsers();

  const {
    message: registerMessage,
    loading,
    onSubmitRegisterRequest,
    onErrorDialogWithMessageClose: onCloseRegisterMessage,
  } = useRegister();

  const { onSubmitAlreadyRegisteredUser } = useAlreadyRegisteredUser();

  const handleOnClick = useCallback(() => {
    onSubmitAlreadyRegisteredUser();
  }, [onSubmitAlreadyRegisteredUser]);

  const onSubmit = useCallback(
    (values: RegisterUserFormSchema) => {
      const payload = {
        coupon: referralCode,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        ddd: values.ddd,
        telephone: values.telephone,
      };

      onSubmitRegisterRequest(payload);
    },
    [onSubmitRegisterRequest, referralCode]
  );

  if (!user || loading) {
    return (
      <div className={classes.root}>
        <Progress />
      </div>
    );
  }

  return (
    <>
      <Box id='home-container' className={classes.root}>
        <Card className={classes.card}>
          <Header data={user} />
          <CardContent className={classes.cardContent}>
            <Box className={classes.buttonContainer}>
              <Button className={classes.buttonStyle} onClick={handleOnClick}>
                <Typography className={classes.buttonText}>
                  Já é cadastrado? Clique aqui!
                </Typography>
              </Button>
            </Box>
            <Typography className={classes.subtitle} variant='subtitle1'>
              ou cadastre-se abaixo.
            </Typography>
            <RegisterForm onSubmit={onSubmit} />
            <Typography className={classes.subtitle} variant='subtitle2'>
              {user.terms}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <StandardDialog
        id='home-get-users-error-dialog'
        title='Ops,'
        text={getUsersMessage}
        open={getUsersMessage !== undefined && getUsersMessage !== ''}
        onCloseClick={onCloseGetUsersMessage}
      />
      <StandardDialog
        id='home-get-users-error-dialog'
        title='Ops,'
        text={registerMessage}
        open={registerMessage !== undefined && registerMessage !== ''}
        onCloseClick={onCloseRegisterMessage}
      />
    </>
  );
}
