import { useCallback } from 'react';
import { useHistory } from 'react-router';

interface UseAlreadyRegisteredUser {
  (): {
    onSubmitAlreadyRegisteredUser: () => void;
  };
}

export const useAlreadyRegisteredUser: UseAlreadyRegisteredUser = () => {
  const history = useHistory();

  const onSubmitAlreadyRegisteredUser = useCallback(() => {
    history.push('/success');
  }, [history]);

  return {
    onSubmitAlreadyRegisteredUser,
  };
};
