import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },
    card: {
      borderRadius: 0,
      margin: 0,
      width: '100%',
      maxWidth: '29rem',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    buttonGroup: {
      display: 'flex',
      marginTop: theme.spacing(6),
    },
    storeAncor: {
      transition: 'transform 0.1s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
    storeImage: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  })
);
