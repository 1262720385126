import { useState, useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { OnSubmitValidateRequest } from '../../model/validate-user-request';
import { getEndpoints } from '../../helpers/configs';

interface UseValidateUser {
  (): {
    loading: boolean;
    onSubmitValidationRequest: OnSubmitValidateRequest;
    message: string;
    onErrorDialogWithMessageClose: () => void;
  };
}

export const useValidateUser: UseValidateUser = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const onErrorDialogWithMessageClose = useCallback(() => {
    setMessage('');
  }, []);

  const history = useHistory();

  const endpoints = getEndpoints();

  const onSubmitValidationRequest: OnSubmitValidateRequest = useCallback(
    async (data) => {
      setLoading(true);

      try {
        await axios.post(`${endpoints.api}v2/mobile/user/validate`, data);

        setLoading(false);
        history.push('/success');
      } catch {
        setLoading(false);
        setMessage('Não foi possível validar os dados.');
      }
    },
    [endpoints.api, history, setMessage]
  );

  return { loading, onSubmitValidationRequest, message, onErrorDialogWithMessageClose };
};
