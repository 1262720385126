import React from 'react';
import { Toolbar } from '@material-ui/core';
import { useStyles } from '../toolbar/toolbar.style';

export default function ToolbarWrapper() {
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar}>
      <img src='https://pedepronto.com.br/images/logo-topo-pede-pronto.png' alt='logo' />
    </Toolbar>
  );
}
