import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ToolbarWrapper from '../toolbar/toolbar';
import { useStyles } from './header.style';

interface Props {
  data: {
    first_name: string;
    value: string;
  };
}

export default function Header(props: Props) {
  const classes = useStyles();
  return (
    <>
      <ToolbarWrapper />
      <Box marginBottom={4}>
        <Typography variant='h2' color='primary' align='center' component='div'>
          {props.data.first_name} oferece 1 cupom* somando R$ {props.data.value} em sua primeira
          compra!
        </Typography>
        <Typography className={classes.subtitle} variant='subtitle1'>
          O crédito será adicionado em sua conta após o cadastro.
        </Typography>
      </Box>
    </>
  );
}
