import { useState, useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { OnSubmitRegisterRequest } from '../../model/register-request';
import { getEndpoints } from '../../helpers/configs';

interface UseRegister {
  (): {
    loading: boolean;
    message: string;
    onSubmitRegisterRequest: OnSubmitRegisterRequest;
    onErrorDialogWithMessageClose: () => void;
  };
}

export const useRegister: UseRegister = () => {
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState('');

  const onErrorDialogWithMessageClose = useCallback(() => {
    setMessage('');
  }, []);

  const history = useHistory();

  const endpoints = getEndpoints();

  const onSubmitRegisterRequest: OnSubmitRegisterRequest = useCallback(
    async (data) => {
      setLoading(true);

      try {
        const { coupon, email, firstName, lastName, password, ddd, telephone } = data;

        const fullTelephone = `${ddd}${telephone}`;

        const response = await axios.post<{ token: string }>(
          `${endpoints.api}v1/mobile/temporary_user`,
          {
            coupon,
            email,
            firstName,
            lastName,
            password,
            telephone: fullTelephone,
          }
        );

        setLoading(false);
        history.push('/validation', { ...data, token: response.data.token });
      } catch {
        setLoading(false);
        setMessage('Não foi possível cadastrar o usuário');
      }
    },
    [endpoints.api, history, setMessage]
  );
  return {
    message,
    loading,
    onSubmitRegisterRequest,
    onErrorDialogWithMessageClose,
  };
};
