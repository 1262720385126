import React, { ReactElement, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';
import { getOnlyDigits } from '../../helpers/get-only-digits';
import { formatDDDNumber, formatPhoneNumber } from '../../helpers/format-phone-number';
import { FieldFormatType } from '../../model/field-format-type';
import { GenericInput } from '../../model/generic-input';

export default function GenericTextField(props: GenericInput): ReactElement {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (event) => {
      switch (props.fieldFormatType) {
        case FieldFormatType.NUMERICAL:
          setFieldValue(field.name, getOnlyDigits(event.target.value));
          break;
        case FieldFormatType.TELEPHONE:
          setFieldValue(field.name, formatPhoneNumber(event.target.value));
          break;
        case FieldFormatType.DDD:
          setFieldValue(field.name, formatDDDNumber(event.target.value));
          break;
        default:
          setFieldValue(field.name, event.target.value);
          break;
      }
    },
    [field.name, setFieldValue, props]
  );

  return (
    <TextField
      id={props.id}
      className={props.className}
      label={props.label}
      name={props.name}
      placeholder={props.placeholder}
      type={props.fieldType}
      value={field.value}
      fullWidth={true}
      disabled={props.disabled}
      onChange={handleChange}
      onBlur={field.onBlur}
      error={meta.error && meta.touched ? true : false}
      helperText={meta.error}
    />
  );
}
