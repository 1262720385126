import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { useStyles } from './standard-dialog.styles';

interface Props {
  id: string;
  open: boolean;
  title: string;
  text: string;
  onCloseClick: () => void;
  onConfirmClick?: () => void;
}

export default function StandardDialog(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Dialog id={props.id} onClose={props.onCloseClick} open={props.open}>
      <DialogTitle className={classes.dialogTitle}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      {props.onConfirmClick && (
        <Button
          data-testid='confirmation-button'
          className={classes.button}
          color='secondary'
          autoFocus={true}
          onClick={props.onConfirmClick}
        >
          Confirmar
        </Button>
      )}
      <Button
        data-testid='close-button'
        className={classes.button}
        variant='outlined'
        color='primary'
        autoFocus={true}
        onClick={props.onCloseClick}
      >
        Fechar
      </Button>
    </Dialog>
  );
}
