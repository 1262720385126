import { getOnlyDigits } from './get-only-digits';

export function getPhoneNumberRegex(): RegExp {
  return new RegExp('^\\d{9}$');
}

export function getDDDRegex(): RegExp {
  return new RegExp('^\\d{2}$');
}

/*
 * Formats a number string into the regular: "XXXXXXXXX".
 */
export function formatPhoneNumber(value?: string): string {
  if (!value) {
    return '';
  }

  const phoneNumber = getOnlyDigits(value);

  const formattedValue = phoneNumber.substring(0, 9);

  return formattedValue;
}

/*
 * Formats a number string into the regular: "XX".
 */
export function formatDDDNumber(value?: string): string {
  if (!value) {
    return '';
  }

  const dddNumber = getOnlyDigits(value);

  const formattedValue = dddNumber.substring(0, 2);

  return formattedValue;
}
