import React, { ReactElement, useCallback, useState } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useValidateUser } from '../../services/use-validate-user/use-validate-user';
import { useUpdateRegister } from '../../services/use-update-register/use-update-register';
import { UpdateUserSchema } from '../../model/update-request';

import PhoneEditionForm from '../../components/phone-edition-form/phone-edition-form';
import ValidationForm from '../../components/validation-form/validation-form';
import ToolbarWrapper from '../../components/toolbar/toolbar';
import Progress from '../../components/progress/progress';
import StandardDialog from '../../components/standard-dialog/standard-dialog';

import { useStyles } from './validation.style';

export default function Validation(): ReactElement {
  const classes = useStyles();

  const validationProps = useLocation<{
    coupon: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    token: string;
    ddd: string;
    telephone: string;
  }>();

  const [ddd, setDdd] = useState(validationProps.state.ddd);
  const [telephone, setTelephone] = useState(validationProps.state.telephone);
  const [phoneEditionInitialValues, setPhoneEditionInitialValues] = useState<UpdateUserSchema>({
    newDDD: validationProps.state.ddd,
    newTelephone: validationProps.state.telephone,
  });

  const {
    loadingUpdateRegister,
    onSubmitUpdateUser,
    token,
    message: updateMessage,
    onErrorDialogWithMessageClose: onCloseUpdateMessage,
  } = useUpdateRegister();
  const {
    onSubmitValidationRequest,
    message: validateMessage,
    onErrorDialogWithMessageClose: onCloseValidateMessage,
  } = useValidateUser();

  const onSubmitPhoneEditionForm = useCallback(
    (values: UpdateUserSchema) => {
      const payload = {
        coupon: validationProps.state.coupon,
        firstName: validationProps.state.firstName,
        lastName: validationProps.state.lastName,
        email: validationProps.state.email,
        password: validationProps.state.password,
        ddd: values.newDDD,
        telephone: values.newTelephone,
        token,
      };

      setDdd(values.newDDD);
      setTelephone(values.newTelephone);
      setPhoneEditionInitialValues({
        newDDD: values.newDDD,
        newTelephone: values.newTelephone,
      });

      onSubmitUpdateUser(payload);
    },
    [onSubmitUpdateUser, validationProps.state, token]
  );

  const onSubmitValidationForm = useCallback(
    (values: { validation: string }) => {
      const payload = {
        token,
        telephone: `${ddd}${telephone}`,
        validation_code: values.validation,
      };

      onSubmitValidationRequest(payload);
    },
    [onSubmitValidationRequest, token, ddd, telephone]
  );

  if (loadingUpdateRegister) {
    return (
      <div className={classes.root}>
        <Progress />
      </div>
    );
  }

  return (
    <div id='validation-container' className={classes.root}>
      <Card className={classes.card}>
        <ToolbarWrapper />
        <CardContent className={classes.cardContent}>
          <Typography variant='h6' align='center' component='div' className={classes.title}>
            Digite o código que será enviado em seu telefone após o cadastro
          </Typography>
          <PhoneEditionForm
            phoneEditionInitialValues={phoneEditionInitialValues}
            onSubmit={onSubmitPhoneEditionForm}
          />
          <Typography className={classes.subtitle} variant='subtitle2'>
            Você está prestes a receber seu benefício.
          </Typography>
          <ValidationForm onSubmitValidationForm={onSubmitValidationForm} />
          <Typography className={classes.subtitle2} variant='subtitle2'>
            * Não cumulativo com outros descontos.
          </Typography>
          <Typography className={classes.subtitle2} variant='subtitle2'>
            * Limitado a 80% do valor da compra.
          </Typography>
        </CardContent>
      </Card>
      <StandardDialog
        id='validation-error-dialog'
        title='Ops,'
        text={validateMessage}
        open={!!validateMessage}
        onCloseClick={onCloseValidateMessage}
      />
      <StandardDialog
        id='update-error-dialog'
        title='Ops,'
        text={updateMessage}
        open={!!updateMessage}
        onCloseClick={onCloseUpdateMessage}
      />
    </div>
  );
}
