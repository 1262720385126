import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import { object, string } from 'yup';
import { FieldFormatType } from '../../model/field-format-type';

import GenericTextField from '../generic-text-field/generic-text-field';

import { useStyles } from './validation-form.style';

interface Props {
  onSubmitValidationForm: (values: any) => void;
}

export default function ValidationForm(props: Props): ReactElement {
  const classes = useStyles();

  const validationFormSchema = object().shape({
    validation: string().required('O campo de confirmação é obrigatório.'),
  });

  const validationFormInitialValues = { validation: '' };

  return (
    <Formik
      validationSchema={validationFormSchema}
      initialValues={validationFormInitialValues}
      onSubmit={props.onSubmitValidationForm}
    >
      <Form data-testid='validation-form'>
        <Box marginBottom={2} className={classes.confirmationCodeWrapper}>
          <GenericTextField
            id='validation'
            label='Código de confirmação'
            name='validation'
            fieldType='text'
            fieldFormatType={FieldFormatType.NUMERICAL}
            className={classes.confirmationCodeNumber}
          />
          <Box className={classes.buttonContainer}>
            <Button className={classes.buttonStyle} type='submit'>
              <Typography className={classes.buttonText}>Já é cadastrado? Clique aqui!</Typography>
            </Button>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
}
