import React, { ReactElement, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Box, Button } from '@material-ui/core';
import { UpdateUserSchema } from '../../model/update-request';
import { FieldFormatType } from '../../model/field-format-type';
import { getDDDRegex, getPhoneNumberRegex } from '../../helpers/format-phone-number';
import GenericTextField from '../generic-text-field/generic-text-field';

import { useStyles } from './phone-edition-form.style';

interface Props {
  phoneEditionInitialValues: {
    newDDD: string;
    newTelephone: string;
  };
  onSubmit: (values: UpdateUserSchema) => void;
}

export default function PhoneEditionForm(props: Props): ReactElement {
  const [disabled, setDisabled] = useState(true);
  const classes = useStyles();

  const handleEditClick = useCallback(() => {
    setDisabled(!disabled);
  }, [disabled]);

  const handleSubmit = useCallback(
    (values: UpdateUserSchema) => {
      props.onSubmit(values);
    },
    [props]
  );

  const phoneEditionFormSchema = object().shape({
    newDDD: string()
      .required('O campo DDD é obrigatório.')
      .matches(getDDDRegex(), 'O campo DDD precisa conter dois dígitos.'),
    newTelephone: string()
      .required('O campo Celular é obrigatório.')
      .matches(getPhoneNumberRegex(), 'O campo Celular precisa conter nove dígitos.'),
  });

  return (
    <Formik
      validationSchema={phoneEditionFormSchema}
      initialValues={props.phoneEditionInitialValues}
      onSubmit={handleSubmit}
    >
      <Form data-testid='phone-edition-form'>
        <Box marginBottom={2} className={classes.phoneWrapper}>
          <GenericTextField
            id='update-ddd'
            label='DDD'
            name='newDDD'
            fieldType='text'
            fieldFormatType={FieldFormatType.DDD}
            className={classes.phonePrefix}
            disabled={disabled}
          />
          <GenericTextField
            id='update-telephone'
            label='Celular'
            name='newTelephone'
            fieldType='tel'
            fieldFormatType={FieldFormatType.TELEPHONE}
            className={classes.phoneNumber}
            disabled={disabled}
          />
          {!disabled && (
            <Button type='submit' className={classes.secondaryButton}>
              Salvar
            </Button>
          )}
          {disabled && (
            <Button className={classes.secondaryButton} onClick={handleEditClick}>
              Editar
            </Button>
          )}
        </Box>
      </Form>
    </Formik>
  );
}
