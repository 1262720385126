import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.primary.dark,
    },
    subtitle: {
      marginTop: 5,
      textAlign: 'center',
      color: '#666',
    },
  })
);
