import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import { useStyles } from './progress.styles';

export default function Progress() {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='center'>
      <CircularProgress color='primary' className={classes.progress} />
    </Box>
  );
}
