import { createMuiTheme } from '@material-ui/core/styles';

export default function themeProvider() {
  const theme = createMuiTheme({
    typography: {
      h2: {
        fontSize: 26,
        marginTop: 20,
      },
      h4: {
        marginTop: 12,
      },
      subtitle1: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 10,
        fontWeight: 'normal',
      },
    },
    palette: {
      primary: {
        light: '#6c4e97',
        main: '#48227E',
        dark: '#321758',
        contrastText: '#fff',
      },
      secondary: {
        light: '#f48fb1',
        main: '#e91e63',
        dark: '#880e4f',
        contrastText: '#000',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundImage: "url('https://onyo.com/invite/css/assets/bg-form.jpg')",
          },
        },
      },
    },
  });

  return theme;
}
