import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmationCodeWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    confirmationCodeNumber: {
      flex: 1,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonStyle: {
      background: 'linear-gradient(45deg, #321758 10%, #6c4e97 90%)',
      marginTop: '10px',
      marginBottom: '10px',
      fontSize: 16,
      borderRadius: 50,
      border: 0,
      color: '#FFF',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 2px 3px 1px #CCC',
    },
    buttonText: {
      textTransform: 'none',
    },
  })
);
