import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondaryButton: {
      color: theme.palette.secondary.light,
    },
    phoneWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    phonePrefix: {
      marginRight: theme.spacing(1),
      width: '5rem',
    },
    phoneNumber: {
      flex: 1,
    },
  })
);
